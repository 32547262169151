import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrawerIframe from "../common/DrawerIframe";
import { TTP_TEAM_URL } from "../../config";
const DynamicTable = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const [fiduciaryId, setFiduciaryId] = useState(null);
  useEffect(() => {
    if (auth.navCommunity) {
      setFiduciaryId(auth.navCommunity.id);
    }
  }, [auth.navCommunity]);

  const params = [];
  let powerTeamUrl = TTP_TEAM_URL + "/dynamic-table";

  if (fiduciaryId) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + fiduciaryId);
    return <DrawerIframe src={powerTeamUrl} params={params} />;
  }
  return <></>;
};

export default DynamicTable;
