import { React, useEffect, useState } from "react";
import PageHeader from "../common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ModelesSettings.module.scss";
import classNames from "classnames";
import { toast } from "react-toastify";
import Select from "react-select";
import _ from "../../i18n";
import { fetchModelesRepartition, modelesSlice } from "../../store";
import { TableBorderedColumns } from "tamtam-components";
import { saveModel } from "../../api";
// import { onSuccess, onError } from "Utils";

const ModelesSettings = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modeles.data);

  //   const { clientID, year } = useParams();
  const year = "2023";
  const [selectedId, setSelectedId] = useState(1);
  const [typeId, SetTypeId] = useState(1);
  const [modeles, SetModeles] = useState([]);
  const [model, SetModel] = useState([]);
  const [modelId, SetModelId] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("standrddd");
  const [selectedModeleId, setSelectedModeleId] = useState("");
  const [newModele, setNewModele] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.navCommunity) {
      if (data.length === 0) {
        const typeId = null;
        dispatch(fetchModelesRepartition({ year, typeId }));
      }
    }
  }, [auth.navCommunity]);
  useEffect(() => {
    if (data.length !== 0) {
      handleSelectedItemsChange(typeId);
    }
  }, [data]);

  if (data.length !== 0) {
    // if (data.modeles && data.modeles !== []) {
    // SetTypeId(data.modeles["1"]["idType"]);
    // SetModeles(data.modeles["1"]["modeles"])
    // }
  }
  const bordrerdcolumns = [
    { label: "Total", haveHeader: true, isBordered: true, isEditable: false },
    {
      label: "Superviseur",
      haveHeader: true,
      isBordered: true,
      isEditable: true,
    },
    { label: "GD", haveHeader: true, isBordered: true, isEditable: true },
    { label: "SE", haveHeader: true, isBordered: true, isEditable: true },
    { label: "GE", haveHeader: true, isBordered: true, isEditable: true },
    { label: "ScanOp", haveHeader: true, isBordered: true, isEditable: true },
  ];

  const handleSelectedItemsChange = (selectedTypeId) => {
    SetTypeId(selectedTypeId);
    SetModeles(data.modeles[selectedTypeId]["modeles"]);
    SetModel(data.modeles[selectedTypeId]["modeles"][0]["model"]);
    SetModelId(data.modeles[selectedTypeId]["modeles"][0]["idModel"]);
    setSelectedLabel(data.modeles[selectedTypeId]["modeles"][0]["labelModel"]);
    setSelectedModeleId(data.modeles[selectedTypeId]["modeles"][0]["idModel"]);
  };

  const handleInputChange = (value, label, role, rowIndex, field) => {
    // const newValue = event.target.value;

    const numericValue =
      field !== "checked" ? value.replace(/[^0-9]/g, "") : value;
    // Update the categoryList with the new value

    let totalValue = "";
    if (field !== "checked" && field !== "value_heure") {
      totalValue = parseInt(
        parseFloat(
          role !== "GD"
            ? model[rowIndex]["GD"][field].replace(/[^0-9]/g, "")
            : numericValue
        ) +
          parseFloat(
            role !== "SE"
              ? model[rowIndex]["SE"][field].replace(/[^0-9]/g, "")
              : numericValue
          ) +
          parseFloat(
            role !== "GE"
              ? model[rowIndex]["GE"][field].replace(/[^0-9]/g, "")
              : numericValue
          ) +
          parseFloat(
            role !== "Superviseur"
              ? model[rowIndex]["Superviseur"][field].replace(/[^0-9]/g, "")
              : numericValue
          ) +
          parseFloat(
            role !== "ScanOp"
              ? model[rowIndex]["ScanOp"][field].replace(/[^0-9]/g, "")
              : numericValue
          )
      );
    } else if (field === "value_heure") {
      totalValue = parseInt(
        parseFloat(
          model[rowIndex]["GD"]["checked"]
            ? role !== "GD"
              ? model[rowIndex]["GD"]["value_heure"].replace(/[^0-9]/g, "")
              : numericValue
            : 0
        ) +
          parseFloat(
            model[rowIndex]["SE"]["checked"]
              ? role !== "SE"
                ? model[rowIndex]["SE"]["value_heure"].replace(/[^0-9]/g, "")
                : numericValue
              : 0
          ) +
          parseFloat(
            model[rowIndex]["GE"]["checked"]
              ? role !== "GE"
                ? model[rowIndex]["GE"]["value_heure"].replace(/[^0-9]/g, "")
                : numericValue
              : 0
          ) +
          parseFloat(
            model[rowIndex]["Superviseur"]["checked"]
              ? role !== "Superviseur"
                ? model[rowIndex]["Superviseur"]["value_heure"].replace(
                    /[^0-9]/g,
                    ""
                  )
                : numericValue
              : 0
          ) +
          parseFloat(
            model[rowIndex]["ScanOp"]["checked"]
              ? role !== "ScanOp"
                ? model[rowIndex]["ScanOp"]["value_heure"].replace(
                    /[^0-9]/g,
                    ""
                  )
                : numericValue
              : 0
          )
      );
    } else {
      totalValue =
        parseInt(
          parseFloat(
            role !== "GD"
              ? model[rowIndex]["GD"]["checked"]
                ? model[rowIndex]["GD"]["value_heure"].replace(/[^0-9]/g, "")
                : 0
              : value === true
              ? model[rowIndex]["GD"]["value_heure"].replace(/[^0-9]/g, "")
              : 0
          ) +
            parseFloat(
              role !== "SE"
                ? model[rowIndex]["SE"]["checked"]
                  ? model[rowIndex]["SE"]["value_heure"].replace(/[^0-9]/g, "")
                  : 0
                : value === true
                ? model[rowIndex]["SE"]["value_heure"].replace(/[^0-9]/g, "")
                : 0
            ) +
            parseFloat(
              role !== "GE"
                ? model[rowIndex]["GE"]["checked"]
                  ? model[rowIndex]["GE"]["value_heure"].replace(/[^0-9]/g, "")
                  : 0
                : value === true
                ? model[rowIndex]["GE"]["value_heure"].replace(/[^0-9]/g, "")
                : 0
            ) +
            parseFloat(
              role !== "Superviseur"
                ? model[rowIndex]["Superviseur"]["checked"]
                  ? model[rowIndex]["Superviseur"]["value_heure"].replace(
                      /[^0-9]/g,
                      ""
                    )
                  : 0
                : value === true
                ? model[rowIndex]["Superviseur"]["value_heure"].replace(
                    /[^0-9]/g,
                    ""
                  )
                : 0
            ) +
            parseFloat(
              role !== "ScanOp"
                ? model[rowIndex]["ScanOp"]["checked"]
                  ? model[rowIndex]["ScanOp"]["value_heure"].replace(
                      /[^0-9]/g,
                      ""
                    )
                  : 0
                : value === true
                ? model[rowIndex]["ScanOp"]["value_heure"].replace(
                    /[^0-9]/g,
                    ""
                  )
                : 0
            )
        );
    }

    SetModel(
      model.map((row, index) => {
        if (index === rowIndex) {
          const updatedRole = {
            ...row[role],
            [field]: numericValue,
          };

          const updatedTotal = {
            ...row.Total,
            ["value_heure"]:
              field !== "value_percentage"
                ? totalValue
                : model[rowIndex]["Total"]["value_heure"],
            ["value_percentage"]:
              field === "value_percentage"
                ? totalValue
                : model[rowIndex]["Total"]["value_percentage"],
          };

          return {
            ...row,
            [role]: updatedRole,
            Total: updatedTotal,
          };
        }

        return row;
      })
    );

    // setCategoryList(updatedCategoryList);
  };

  const handleColumns = (column, item, index1) => {
    return (
      <>
        {column.label !== "label" &&
          column.label !== "totalPercentage" &&
          item.label !== "header" &&
          item.label !== "footer" && (
            <div style={{ margin: "auto" }}>
              <input
                type="text"
                className={classNames(
                  styles.input_box,
                  styles.label,
                  column.label === "Total" && styles.disabled,
                  column.label === "Total" &&
                    (item[column.label]["value_percentage"] === "100" ||
                      item[column.label]["value_percentage"] === 100) &&
                    styles.sucess_total,
                  column.label === "Total" &&
                    (item[column.label]["value_percentage"] !== "100" ||
                      item[column.label]["value_percentage"] !== 100) &&
                    styles.insucess_total
                )}
                value={item[column.label]["value_percentage"] + " %"}
                required={true}
                style={{
                  border:
                    item["Total"]["value_percentage"] !== "100" &&
                    item["Total"]["value_percentage"] !== 100
                      ? "1px solid #de4848"
                      : ""
                }}
                onChange={(event) =>
                  handleInputChange(
                    event.target.value,
                    item.label,
                    column.label,
                    index1,
                    "value_percentage"
                  )
                }
              ></input>
              <div>
                <input
                  type="text"
                  className={classNames(
                    styles.input_box,
                    styles.label,
                    (column.label === "Total" ||
                      !item[column.label]["checked"]) &&
                      styles.disabled,
                    column.label === "Total" && styles.title_disabled
                  )}
                  value={item[column.label]["value_heure"] + " h"}
                  required={true}
                  disabled={!item[column.label]["checked"]}
                  style={{
                    margin: item.label === "Total" && "auto",
                  }}
                  onChange={(event) =>
                    handleInputChange(
                      event.target.value,
                      item.label,
                      column.label,
                      index1,
                      "value_heure"
                    )
                  }
                ></input>

                {item.label !== "Total" && column.label !== "Total" && (
                  <input
                    type="checkbox"
                    className={classNames(
                      item[column.label]["checked"] && styles.disabled
                    )}
                    checked={item[column.label]["checked"]}
                    onChange={() =>
                      handleInputChange(
                        !item[column.label]["checked"],
                        item.label,
                        column.label,
                        index1,
                        "checked",
                        ""
                      )
                    }
                  ></input>
                )}
              </div>
            </div>
          )}
      </>
    );
  };
  const handleNewModele = () => {
    const newModel = { idModel: null, labelModel: "New model", model: [] };
    const model = [];
    data.categories.forEach((item) => {
      const model = {};
      model["label"] = item.label;
      model["id_category"] = item.id;
      bordrerdcolumns.forEach((colunm) => {
        model[colunm.label] = {
          value_heure: "0",
          value_percentage: colunm.label === "Total" ? "100" : "20",
          checked: false,
        };
      });
      newModel.model.push(model);
    });
    setNewModele(newModel);
    const copiedObject = [];
    copiedObject.push(...modeles);
    copiedObject.push(newModel);
    SetModeles(copiedObject);
    setSelectedLabel(newModel.labelModel);
    SetModel(newModel.model);
    setSelectedModeleId(newModel.idModel);
  };

  const handleChangeSelectedModele = (idModel, model, labelModel) => {
    setSelectedModeleId(idModel);
    setSelectedLabel(labelModel);
    SetModel(model);
  };

  const handleChangeLabelModele = (labelModel) => {
    SetModeles(
      modeles.map((element, i) => {
        if (element.idModel === selectedModeleId) {
          return {
            ...element,
            labelModel: labelModel,
          };
        }
        return element;
      })
    );
    setSelectedLabel(labelModel);
  };

  const handleSave = async () => {
    const modeleToSave = modeles.filter(
      (item) => item.idModel === selectedModeleId
    )[0].model;

    saveModel(selectedModeleId, selectedId, selectedLabel, model, 4, auth.token)
      .then((resp) => {
        if (resp.data.result === "OK") {
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  return (
    <div>
      {/* {data.length !== 0 && ( */}
      <div>
        <PageHeader
          header={_("gestion des taches")}
          //   subHeader={data.client_name}
          //   icon={ICONS.BUDGET_PAGE_ICON}
        />
        {/* <header>tttt</header> */}

        {data.length !== 0 && (
          <div className={styles.page_content}>
            <div className={classNames(styles.content)}>
              <div className={styles.bloc}>
                <div>
                  <div className={styles.title}>
                    modèles de réparations des taches
                  </div>
                  <div className={styles.description}>
                    Pandente itaque viam fatorum sorte tristissima, qua
                    praestitutum erat eum vita et imperio{" "}
                  </div>
                  <div className={classNames(styles.content, "grid-x")}>
                    <div className={styles.client_types}>
                      <div className={styles.sub_title}>Type de société</div>
                      <div
                        className={classNames(
                          styles.types_labels,
                          typeId === 1 && styles.selected_type
                        )}
                        onClick={() => handleSelectedItemsChange(1)}
                      >
                        <span className={styles.type_label}>Client</span>
                        <span className={styles.type_icon}>{">"}</span>
                      </div>
                      <div
                        className={classNames(
                          styles.types_labels,
                          typeId === 2 && styles.selected_type
                        )}
                        onClick={() => handleSelectedItemsChange(2)}
                      >
                        <span className={styles.type_label}>Prospect</span>
                        <span className={styles.type_icon}>{">"}</span>
                      </div>
                    </div>
                    <div className={styles.client_model}>
                      <div>
                        <div className={styles.sub_title}>Modèle attribué</div>
                        <div className={styles.description}>
                          Petite description à prévoir ici...
                        </div>
                        <div className={styles.types}>
                          <div
                            className={styles.new_modele}
                            //to add disabled style if addNewModeleOption === 1
                            onClick={() => handleNewModele()}
                          >
                            +
                          </div>
                          <div className={styles.list_types}>
                            {modeles &&
                              modeles.map((item, i) => {
                                return (
                                  <div
                                    className={classNames(
                                      styles.item,
                                      item.idModel === selectedModeleId &&
                                        styles.selected_modele
                                    )}
                                    onClick={() =>
                                      handleChangeSelectedModele(
                                        item.idModel,
                                        item.model,
                                        item.labelModel
                                      )
                                    }
                                  >
                                    <div>
                                      <div className={styles.label}>
                                        {item.labelModel}
                                      </div>
                                      <span
                                        className={
                                          item.idModel === selectedModeleId &&
                                          styles.selected_modele
                                        }
                                      ></span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className={styles.selected_label}>
                            <input
                              value={selectedLabel}
                              onChange={(event) =>
                                handleChangeLabelModele(event.target.value)
                              }
                              className={styles.label}
                            ></input>
                          </div>
                        </div>
                        <div>
                          {model && (
                            <TableBorderedColumns
                              headerHeight={"58px"}
                              height={"83px"}
                              bordrerdcolumns={bordrerdcolumns}
                              handleColumns={handleColumns}
                              tableItems={model}
                              isGestionTask={false}
                            />
                          )}
                        </div>

                        <div className={styles.footer_actions}>
                          <button className={styles.button_prev}>
                            annuler
                          </button>
                          <button
                            className={styles.button_next}
                            onClick={() => handleSave()}
                          >
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelesSettings;
