import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header } from "tamtam-components";
// import { Link } from "react-router-dom";

import {
  S3_FOLDER_URL,
  TTP_POWERTEAM_URL,
  TTP_HOME_URL,
  APP_ENV,
} from "../../config";
import { setNavCommunity, setAuthUser, setAuthSpaceAs } from "../../store";
import { setCookie, setLocalStorageAuth } from "../../utils";
import _ from "../../i18n";

const Menu = () => {
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);

  const { loggedAs, spaceAs } = auth;
  // const searchVisible = useSelector((state) => state.params.searchVisible);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAppData = () => {
    let data = {
      appName: "PowerTeam",
      appLogoUrl: `${S3_FOLDER_URL}/apps/powerteam.png`,
      appUrl: `${TTP_POWERTEAM_URL}/${lng}`,
      homeUrl: TTP_HOME_URL,
    };
    return data;
  };

  const App = getAppData();

  const rightIcons = {
    home: {
      activated: false,
      url: "https://powerteam.tamtam.pro/" + lng + "",
    },
    profile: {
      activated: false,
    },
    ebox: {
      activated: false,
    },
    search: {
      activated: false,
    },
    notifs: {
      activated: true,
    },
    backoffice: {
      activated: false,
    },
    faq: {
      activated: false,
    },
    apps: {
      activated: false,
    },
  };

  const changeLng = (language) => {
    document.location.href = "/" + language;
  };

  const setCummunity = (community) => {
    if (community) {
      dispatch(setNavCommunity(community));
      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() + 9900000 * 1000);
      setCookie(
        "ttp_community_" + APP_ENV,
        community.id,
        dtExpire,
        "/",
        "tamtam.pro"
      );
    }
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(setAuthUser(null));
    setLocalStorageAuth(null);

    let dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

    setCookie("ttp_auth_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_auth_" + APP_ENV, "", dtExpire, "/");
    setCookie("ttp_community_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_lang_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
    setCookie("ttp_lang_" + APP_ENV, "", dtExpire, "/");
    setCookie("XSRF-TOKEN", "", dtExpire, "/", "tamtam.pro");
    setCookie(`ttp_session_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");

    setTimeout(() => {
      window.location = "/" + lng;
    }, 200);
  };

  // let portalConfig =
  // appsState && appsState.filter(app => app.code === "portal");

  let settingEnabled = spaceAs === "MANAGER" && loggedAs === "MANAGER";
  // ||
  //   (auth.user &&
  //     (auth.user.type === "ADMIN" || auth.user.type === "ADMIN_SUPERVISOR")));

  const settings = settingEnabled
    ? [
        {
          label: _("gestion categories"),
          url: `/${lng}/category-settings`,
        },
        {
          label: _("gestion task types"),
          url: `/${lng}/task-types`,
        },
        {
          label: _("gestion collaborateurs"),
          url: `/${lng}/collaborators-settings`,
        },
        {
          label: _("gestion calendrier"),
          url: `/${lng}/calendar-settings`,
        },
        // {
        //   label: _("parameterSettings"),
        //   url: "/parameter-settings"
        // },
        // {
        //   label: _("users"),
        //   url: "/authorizations"
        // }
        {
          label: _("gestion des modeles"),
          url: `/${lng}/modeles-settings`,
        },
      ]
    : [];
  const switchSpace = {
    items: [
      {
        key: "COLLABORATOR",
        label: "Espace Collaborator",
      },
      {
        key: "MANAGER",
        label: "Espace Manager",
      },
    ],
    current: spaceAs,
    onChange: (space) => {
      if (space === "COLLABORATOR") {
        navigate(`/${lng}/clients`);
      } else if (space === "MANAGER") {
        navigate(`/${lng}/clients`);
      } else {
        navigate(`/${lng}`);
      }
      // this.setState({ ...this.state, switchUser: user });
      // this.setCookieSwitchUser(user);
      dispatch(setAuthSpaceAs(space));
      // <Link to={`/${lng}/clients`} />;
    },
  };

  if (!auth.user) {
    return (
      <Header
        app={App}
        auth={{ navCommunity: null, user: null }}
        lng={lng}
        env={APP_ENV}
        onLanguageChange={(lang) => this.changeLng(lang)}
        rightIcons={rightIcons}
        onSelectCommunity={(community) => this.selectCommunity(community)}
      />
    );
  } else {
    return (
      <Header
        rightIcons={rightIcons}
        app={App}
        auth={{
          navCommunity: auth.navCommunity,
          user: auth.user,
          token: auth.token,
        }}
        settings={settings}
        lng={lng}
        env={APP_ENV}
        switchSpace={loggedAs === "MANAGER" ? switchSpace : ""}
        onLanguageChange={(langue) => changeLng(langue)}
        onSelectCommunity={(community) => setCummunity(community)}
        onLogoutClick={(e) => logout(e)}
        // onLogoutClick={e => this.handleLogout(e)}
        // onSelectCommunity={community => this.selectCommunity(community)}
      />
    );
  }
};

export default Menu;
