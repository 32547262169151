import React, { useState, useRef } from "react";
import { useQuery } from "react-query";

import _ from "../../i18n";
import { useOnClickOutside, renderTeamYears } from "../../utils";
import { getZonesSuggestions } from "../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";

const CollaboratorsFilter = ({
  token,
  filter,
  nbResult,
  organization,
  updateFilter,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    updateFilter({ ...filter, year: item });
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom">
        {renderYearSelect()}
        <div className="mini-tabs-frame">
          {[
            {
              id: "all",
              label: "all",
              value: "",
            },
            {
              id: "actives",
              label: "actifs",
              value: "NOT_DELETED",
            },
            {
              id: "deleted",
              label: "inactifs",
              value: "DELETED",
            },
          ].map((item) => (
            <div
              className={
                filter.deletedStatus === item.value && "mini-tabs-frame_active"
              }
              onClick={() => {
                updateFilter({
                  ...filter,
                  deletedStatus: item.value,
                });
              }}
            >
              {_(item.label)}
              <div className="span">{filter.counters[item.id]}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  let zonesQuery = useQuery(["zonesQuery", organization], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, organization);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone ? filter.zone.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.zone;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              zone: zoneOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
  ];

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
          fetching={zoneOptions.isFetching}
        />
      </div>
    </div>
  );
};

export default CollaboratorsFilter;
