import React from "react";

export const SynchroDownIcon = ({ size = 15, fill = "#29394D" }) => (
  <svg
    width="44"
    height="40"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" fill="#EA8124" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7745 13.9036C21.1489 13.6928 21.5713 13.582 22.001 13.582C22.4306 13.582 22.853 13.6928 23.2274 13.9036C23.6019 14.1144 23.9156 14.4181 24.1385 14.7855L24.1409 14.7894L31.1992 26.5728L31.2059 26.5843C31.4242 26.9623 31.5397 27.3909 31.541 27.8274C31.5422 28.2639 31.4291 28.6932 31.2129 29.0724C30.9967 29.4517 30.685 29.7677 30.3088 29.9891C29.9326 30.2105 29.5049 30.3295 29.0684 30.3343L29.0593 30.3344L14.9335 30.3343C14.497 30.3295 14.0693 30.2105 13.6931 29.9891C13.3169 29.7677 13.0052 29.4517 12.789 29.0724C12.5728 28.6932 12.4597 28.2639 12.4609 27.8274C12.4622 27.3909 12.5777 26.9623 12.796 26.5843L12.8027 26.5728L19.8634 14.7855C20.0863 14.4181 20.4001 14.1144 20.7745 13.9036ZM22.001 15.2487C21.8577 15.2487 21.7169 15.2856 21.5921 15.3559C21.4679 15.4258 21.3637 15.5265 21.2895 15.6482L14.2365 27.4225C14.1655 27.5473 14.128 27.6884 14.1276 27.8321C14.1272 27.9776 14.1649 28.1207 14.237 28.2471C14.309 28.3735 14.4129 28.4788 14.5383 28.5526C14.6626 28.6258 14.8038 28.6654 14.948 28.6677H29.054C29.1981 28.6654 29.3393 28.6258 29.4636 28.5526C29.589 28.4788 29.6929 28.3735 29.7649 28.2471C29.837 28.1207 29.8747 27.9776 29.8743 27.8321C29.8739 27.6884 29.8364 27.5474 29.7654 27.4225L22.7135 15.6498C22.7131 15.6493 22.7128 15.6487 22.7124 15.6482C22.6382 15.5265 22.534 15.4258 22.4098 15.3559C22.285 15.2856 22.1442 15.2487 22.001 15.2487Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.9993 18.668C22.4596 18.668 22.8327 19.0411 22.8327 19.5013V22.8346C22.8327 23.2949 22.4596 23.668 21.9993 23.668C21.5391 23.668 21.166 23.2949 21.166 22.8346V19.5013C21.166 19.0411 21.5391 18.668 21.9993 18.668Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.166 26.1654C21.166 25.7051 21.5391 25.332 21.9993 25.332H22.0077C22.4679 25.332 22.841 25.7051 22.841 26.1654C22.841 26.6256 22.4679 26.9987 22.0077 26.9987H21.9993C21.5391 26.9987 21.166 26.6256 21.166 26.1654Z"
      fill="white"
    />
  </svg>
);
