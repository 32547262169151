import React from "react";

export const IconPencil = ({ size = 12 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1844 1.81467C20.1701 -0.204826 16.8944 -0.204826 14.8801 1.81467L1.4882 15.2032C1.3835 15.3079 1.31869 15.4425 1.29874 15.5872L0.306565 22.9371C0.27665 23.1466 0.351437 23.356 0.496026 23.5006C0.620672 23.6253 0.795175 23.7 0.969679 23.7C0.999594 23.7 1.02951 23.7 1.05942 23.6951L5.48683 23.0967C5.85578 23.0468 6.11505 22.7078 6.06519 22.3388C6.01533 21.9698 5.6763 21.7105 5.30734 21.7603L1.75744 22.239L2.45047 17.113L7.84513 22.5083C7.96977 22.633 8.14428 22.7078 8.31878 22.7078C8.49329 22.7078 8.66779 22.6379 8.79243 22.5083L22.1844 9.11977C23.1616 8.14243 23.7 6.84596 23.7 5.46473C23.7 4.08349 23.1616 2.78702 22.1844 1.81467ZM15.1394 3.46019L17.388 5.70906L5.16774 17.9308L2.91914 15.6819L15.1394 3.46019ZM8.32377 21.0822L6.12502 18.8832L18.3453 6.66147L20.544 8.86048L8.32377 21.0822ZM21.4814 7.8981L16.1017 2.51776C16.7847 1.95429 17.6373 1.64513 18.5347 1.64513C19.5568 1.64513 20.5141 2.04405 21.237 2.76209C21.96 3.48013 22.3539 4.44251 22.3539 5.46473C22.3539 6.36727 22.0448 7.21496 21.4814 7.8981Z"
      fill="currentColor"
    />
  </svg>
);

export const IconPencil2 = ({ size = 12, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.50001 1.58594C9.37996 1.58594 9.26108 1.60958 9.15016 1.65553C9.03924 1.70147 8.93846 1.76881 8.85357 1.8537L2.19746 8.50981L1.71262 10.2875L3.49035 9.80271L10.1465 3.1466C10.2314 3.0617 10.2987 2.96092 10.3446 2.85001C10.3906 2.73909 10.4142 2.62021 10.4142 2.50015C10.4142 2.38009 10.3906 2.26121 10.3446 2.1503C10.2987 2.03938 10.2314 1.9386 10.1465 1.8537C10.0616 1.76881 9.96078 1.70147 9.84987 1.65553C9.73895 1.60958 9.62007 1.58594 9.50001 1.58594ZM8.76747 0.731648C8.99972 0.63545 9.24863 0.585938 9.50001 0.585938C9.75139 0.585938 10.0003 0.63545 10.2325 0.731648C10.4648 0.827846 10.6758 0.968846 10.8536 1.1466C11.0313 1.32435 11.1723 1.53537 11.2685 1.76761C11.3647 1.99986 11.4142 2.24877 11.4142 2.50015C11.4142 2.75153 11.3647 3.00044 11.2685 3.23269C11.1723 3.46493 11.0313 3.67595 10.8536 3.8537L4.10356 10.6037C4.04204 10.6652 3.96552 10.7096 3.88157 10.7325L1.13157 11.4825C0.958464 11.5297 0.773333 11.4806 0.646458 11.3537C0.519583 11.2268 0.470419 11.0417 0.51763 10.8686L1.26763 8.11859C1.29052 8.03465 1.33493 7.95813 1.39646 7.8966L8.14646 1.1466C8.32421 0.968846 8.53523 0.827846 8.76747 0.731648Z"
      fill={fill}
    />
  </svg>
);
