import React, { useState, useRef, Fragment } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import _ from "../../i18n";

import {
  useOnClickOutside,
  renderAvatar,
  setLocaleCalendarData,
} from "../../utils";

import {
  saveDevelopementComment,
  saveDevelopementCardPlan,
  deleteDevelopementPlan,
  deleteDevelopementComment,
  getDevelopementCard,
  getCollaboratorAvatars,
  getOrganizationCollaborators,
} from "../../api";

import {
  IconPlus,
  BellIcon,
  UserIcon,
  ClockIcon,
  IconClose,
  IconTrash,
  CalendarIcon,
  IconPencil2,
  BarsIcon,
  BookMarkIcon,
  FrequanceIcon,
  IconChevronDown,
} from "../common/Icons";

import { Calendar } from "primereact/calendar";
import { ModalConfirm } from "tamtam-components";
import { TiArrowSortedDown } from "react-icons/ti";
import { PRIORITY_LEVELS } from "../../config";

import "../CollaboratorSpace/ProductionTracking/EncodingProgression/EncodingProgressionContent.local.scss";

const IMPORTANCE_LEVELS = [
  { label: "low", level: 1 },
  { label: "moyen", level: 2 },
  { label: "high", level: 3 },
];

const CollaboratorDevelopmentCard = ({
  lng,
  user,
  year,
  token,
  onClose,
  loggedAs,
  collaborator,
  organization,
}) => {
  const refPlans = useRef();
  const refPriority = useRef();
  const refImportance = useRef();
  const refProgression = useRef();
  const refResponsables = useRef();
  //States
  useOnClickOutside(refPlans, () => setPlanDivIsVisible(false));
  useOnClickOutside(refPriority, () => setPriorityDivIsVisible(false));
  useOnClickOutside(refProgression, () => setStepsDivIsVisible(false));
  useOnClickOutside(refImportance, () => setImportanceDivIsVisible(false));
  useOnClickOutside(refResponsables, () => setResponsablesDivIsVisible(false));

  const [planDivIsVisible, setPlanDivIsVisible] = useState(false);
  const [sortDivIsVisible, setSortDivIsVisible] = useState(false);
  const [stepsDivIsVisible, setStepsDivIsVisible] = useState(false);
  const [priorityDivIsVisible, setPriorityDivIsVisible] = useState(false);
  const [importanceDivIsVisible, setImportanceDivIsVisible] = useState(false);
  const [responsablesDivIsVisible, setResponsablesDivIsVisible] =
    useState(false);

  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] =
    useState(null);
  const [isDeletePlanModalOpen, setIsDeletePlanModalOpen] = useState(false);

  const [cardComments, setCardComments] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [avatarCollaborators, setAvatarCollaborators] = useState([]);
  const [collaboratorsKeyWord, setCollaboratorsKeyWord] = useState("");
  const [commentsSortBy, setCommentsSortBy] = useState("moreRecent");

  const [devCard, setDevCard] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [firstPlan, setFirstPlan] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [tempPlan, setTempPlan] = useState(null);
  const [tempComment, setTempComment] = useState(null);
  const [editedPlan, setEditedPlan] = useState(false);
  const [editedComment, setEditedComment] = useState(null);

  setLocaleCalendarData(lng);

  let collaboratorsQuery = useQuery(
    ["collaboratorsQuery", organization, collaboratorsKeyWord],
    async () => {
      if (token) {
        try {
          const response = await getOrganizationCollaborators(
            organization,
            year,
            token,
            null,
            collaboratorsKeyWord
          );
          setCollaborators(response.data.data);
        } catch (e) {
          setCollaborators([]);
          return null;
        }
      }
    }
  );

  let collaboratorAvatarsQuery = useQuery(
    ["collaboratorAvatarsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorAvatars(organization, token);
          setAvatarCollaborators(response.data.data);
        } catch (e) {
          setAvatarCollaborators([]);
          return null;
        }
      }
    }
  );

  let developementCardsQuery = useQuery(
    ["developementCardsQuery", organization],
    async () => {
      try {
        const response = await getDevelopementCard(
          [collaborator.id],
          organization,
          token,
          { year }
        );
        let cards = response.data.data;
        if (cards.length > 0) {
          let card = cards[0];
          let indexedPlans = card.plans.map((plan, range) => {
            return { ...plan, index: card.plans.length - range - 1 };
          });
          setDevCard({ ...card, plans: indexedPlans });
          setActivePlan({
            ...card.plans[0],
            deadLine: moment(card.plans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
          });
          prepareCommentsCard({ ...card, plans: indexedPlans });
        } else {
          setFirstPlan(true);
          setTempPlan({
            title: "NEW P",
            responsables: [],
            priorityLevel: { label: "MEDIUM", step: "medium" },
            progression: "TODO",
          });
        }
      } catch (e) {
        return null;
      }
    }
  );

  const prepareCommentsCard = (item) => {
    setCardComments([]);
    let cardCommentsLocal = [];
    item.plans.forEach((plan) => {
      plan.comments &&
        plan.comments.length &&
        plan.comments.forEach((comment) => {
          cardCommentsLocal.push({
            ...comment,
            plan: { name: `PLAN ${plan.index + 1}`, id: plan.id },
          });
        });
    });
    item.comments.length > 0 &&
      item.comments.forEach((remark) => {
        cardCommentsLocal.push({
          ...remark,
          card: { name: _(`allPlans`), id: item.id },
        });
      });
    cardCommentsLocal = sortCommentsCard(cardCommentsLocal);
    setCardComments(cardCommentsLocal);
  };

  const sortCommentsCard = (comments, sort = null) => {
    comments.sort((a, b) => {
      if (sort === "morePrioritaire") {
        return a.importance < b.importance
          ? 1
          : b.importance < a.importance
          ? -1
          : 0;
      } else {
        return a.code < b.code ? 1 : b.code < a.code ? -1 : 0;
      }
    });
    return comments;
  };

  const handleSavePlan = async (plan) => {
    try {
      const response = await saveDevelopementCardPlan({
        card: devCard ? devCard.id : null,
        year,
        token,
        organization,
        plan: plan,
        collaborator: collaborator.id,
        user: user,
      });
      if (devCard) {
        let planResponse = response.data.data;
        let newPlans = [];
        if (plan.id) {
          newPlans = devCard.plans.map((item) => {
            if (item.id === plan.id) {
              planResponse = { ...item, ...planResponse };
              return planResponse;
            } else {
              return item;
            }
          });
          setDevCard({ ...devCard, plans: newPlans });
          setActivePlan({
            ...planResponse,
            deadLine: moment(planResponse.deadLine, "Y-MM-DD HH:mm:ss")._d,
          });
          setEditedPlan(null);
        } else {
          newPlans = [...devCard.plans];
          planResponse = { ...planResponse, index: devCard.plans.length };
          newPlans.unshift(planResponse);
          setDevCard({ ...devCard, plans: newPlans });
          setAddPlan(false);
          setTempPlan(null);
        }
        setActivePlan({
          ...planResponse,
          deadLine: moment(planResponse.deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
      } else {
        let card = response.data.data;
        let indexedPlans = card.plans.map((plan, range) => {
          return { ...plan, index: card.plans.length - range - 1 };
        });
        setFirstPlan(false);
        setDevCard({ ...card, plans: indexedPlans });
        setActivePlan({
          ...card.plans[0],
          deadLine: moment(card.plans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
        prepareCommentsCard({ ...card, plans: indexedPlans });
      }
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const deleteDevelopementCardPlan = async () => {
    try {
      const response = await deleteDevelopementPlan({
        token,
        organization,
        plan: activePlan.id,
      });
      let planResponse = response.data.data;
      if (devCard.plans.length > 1) {
        let index = devCard.plans.length - 1;
        let newPlans = devCard.plans
          .filter((plan) => plan.id !== planResponse.plan)
          .map((plan) => {
            index--;
            return { ...plan, index };
          });
        setActivePlan({
          ...newPlans[0],
          deadLine: moment(newPlans[0].deadLine, "Y-MM-DD HH:mm:ss")._d,
        });
        setDevCard({ ...devCard, plans: newPlans });
      } else {
        setTempPlan({
          title: "NEW P",
          responsables: [],
          priorityLevel: { label: "MEDIUM", step: "medium" },
          progression: "TODO",
        });
        setActivePlan(null);
        setDevCard(null);
        setFirstPlan(true);
      }
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleSaveComment = async (item) => {
    try {
      const response = await saveDevelopementComment({
        token,
        organization,
        plan: item.plan ? item.plan.id : null,
        card: devCard.id,
        comment: {
          code: item.code,
          message: item.message,
          importance: item.importance,
        },
        user: user,
      });
      let commentResponse = response.data.data;
      let newComments = [];
      if (item.code) {
        newComments = cardComments.map((comment) => {
          if (comment.code === commentResponse.code) {
            return { ...item, ...commentResponse };
          }
          return comment;
        });
        setEditedComment(null);
      } else {
        newComments = [...cardComments];
        newComments.unshift({ ...item, ...commentResponse });
        setTempComment(null);
      }
      setCardComments(newComments);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteDevelopementComment = async (item) => {
    try {
      const response = await deleteDevelopementComment({
        token,
        organization,
        comment: item,
      });
      let commentResponse = response.data.data;
      let newComments = [];
      newComments = cardComments.filter((comment) => {
        return comment.code !== commentResponse.code;
      });
      setCardComments(newComments);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const renderDevelopementCardsFrame = () => {
    return (
      <Fragment>
        <div style={{ width: firstPlan ? "" : "70%" }}>
          <div className="tags">
            {!addPlan && (
              <div
                onClick={() => {
                  setAddPlan(true);
                  setActivePlan(null);
                  setTempPlan({
                    title: "NEW PLAN",
                    responsables: [],
                    priorityLevel: { label: "MEDIUM", step: "medium" },
                    progression: "TODO",
                  });
                }}
              >
                <IconPlus fill="#6D7F92" />
              </div>
            )}
            {addPlan && !activePlan && (
              <div className="tag success">{`PLAN ${
                devCard.plans.length + 1
              }`}</div>
            )}
            {devCard &&
              devCard.plans.map((item, index) => (
                <div
                  className={
                    "tag" +
                    " " +
                    (activePlan && item.id === activePlan.id ? "active" : "")
                  }
                  onClick={() => {
                    setActivePlan({
                      ...item,
                      deadLine: moment(item.deadLine, "Y-MM-DD HH:mm:ss")._d,
                    });
                    if (editedPlan) {
                      setEditedPlan(null);
                    }
                  }}
                >
                  <div className={`dot ${item.progression}_dot`} />
                  {`PLAN ${item.index + 1}`}
                </div>
              ))}
            {activePlan && (
              <div
                className="action"
                onClick={() => {
                  setIsDeletePlanModalOpen(true);
                }}
              >
                <IconTrash size={12} />
              </div>
            )}
          </div>
          {addPlan && !activePlan && renderLightFrame()}
          {renderHeavyFrame()}

          {editedPlan && (
            <div className="footer" style={{ width: "53%" }}>
              <div
                className="action_left"
                onClick={() => {
                  setEditedPlan(null);
                }}
              >
                {_("cancel")}
              </div>
              <div
                className={
                  "action_right" +
                  " " +
                  (editedPlan.title === "" ||
                  editedPlan.responsables.length === 0
                    ? "disabled"
                    : "")
                }
                onClick={() => {
                  if (
                    editedPlan.title === "" ||
                    editedPlan.responsables.length === 0
                  ) {
                    return;
                  } else {
                    handleSavePlan({
                      ...editedPlan,
                      deadLine:
                        editedPlan.deadLine instanceof Date &&
                        !isNaN(editedPlan.deadLine)
                          ? moment(editedPlan.deadLine).format(
                              "Y-MM-DD HH:mm:ss"
                            )
                          : null,
                    });
                  }
                }}
              >
                {_("save")}
              </div>
            </div>
          )}
        </div>
        {!firstPlan && (
          <div className="pannel" style={{ height: 604 }}>
            <div className="pannel_header">
              <label>{_("remarks")} : </label>
              <div
                className="action"
                onClick={() => setSortDivIsVisible(!sortDivIsVisible)}
              >
                <div className="action_header">
                  <span>{_(commentsSortBy)}</span>
                  <IconChevronDown width="10" height="6" />
                </div>
                {sortDivIsVisible && (
                  <div className="action_body">
                    {["moreRecent", "morePrioritaire"].map((item) => (
                      <div
                        className="option"
                        onClick={() => {
                          setCommentsSortBy(item);
                          setCardComments(sortCommentsCard(cardComments, item));
                        }}
                      >
                        {_(item)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {cardComments.length > 0 && (
              <div className="infos-set">
                <div
                  className="comments"
                  style={{
                    maxHeight: 471,
                  }}
                >
                  {cardComments.map((remark) => {
                    return editedComment &&
                      editedComment.code === remark.code ? (
                      <div className="textarea-form">
                        <div className="set">
                          <span>Nv. Importance : </span>
                          <div
                            ref={refImportance}
                            className={
                              "rectangular_select" +
                              " " +
                              (importanceDivIsVisible
                                ? "rectangular_select_opened"
                                : "")
                            }
                          >
                            <div
                              className={"rectangular_select_header"}
                              onClick={() =>
                                setImportanceDivIsVisible(
                                  !importanceDivIsVisible
                                )
                              }
                            >
                              <span>
                                {
                                  IMPORTANCE_LEVELS[
                                    editedComment.importance - 1
                                  ].label
                                }
                              </span>
                              <TiArrowSortedDown />
                            </div>
                            {importanceDivIsVisible && (
                              <div className={"rectangular_select_body"}>
                                <div
                                  className={"rectangular_select_body_options"}
                                >
                                  {IMPORTANCE_LEVELS.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          "rectangular_select_body_option"
                                        }
                                        onClick={() => {
                                          setEditedComment({
                                            ...editedComment,
                                            importance: item.level,
                                          });
                                          setImportanceDivIsVisible(false);
                                        }}
                                      >
                                        {_(item.label)}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="set">
                          <span>Afficher sur : </span>
                          <div
                            ref={refPlans}
                            className={
                              "rectangular_select" +
                              " " +
                              (planDivIsVisible
                                ? "rectangular_select_opened"
                                : "")
                            }
                          >
                            <div
                              className={"rectangular_select_header"}
                              onClick={() =>
                                setPlanDivIsVisible(!planDivIsVisible)
                              }
                            >
                              <span>
                                {editedComment.plan && editedComment.plan.name}
                              </span>
                              <TiArrowSortedDown />
                            </div>
                            {planDivIsVisible && (
                              <div className={"rectangular_select_body"}>
                                <div
                                  className={"rectangular_select_body_options"}
                                >
                                  {devCard.plans.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          "rectangular_select_body_option"
                                        }
                                        onClick={() => {
                                          setTempComment({
                                            ...tempComment,
                                            plan: {
                                              id: item.id,
                                              name: item.name,
                                            },
                                          });
                                          setPlanDivIsVisible(false);
                                        }}
                                      >
                                        {editedComment.plans.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <textarea
                          value={editedComment.message}
                          onChange={(e) =>
                            setEditedComment({
                              ...editedComment,
                              message: e.target.value,
                            })
                          }
                        />
                        <div className="actions">
                          <div
                            className="default"
                            onClick={() => setEditedComment(null)}
                          >
                            <IconClose size="10" fill="#6D7F92" />
                          </div>
                          <div
                            className="premium"
                            onClick={() => handleSaveComment(editedComment)}
                          >
                            {_("Edit")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="comment">
                        <div className="comment_header">
                          <div className="comment_user">
                            <div className="avatar" key={"item.uid"}>
                              {renderAvatar(null, remark.user)}
                            </div>
                            <div className="comment_user_details">
                              <span>{remark.user}</span>
                              <span>GD</span>
                            </div>
                          </div>
                          <div className="actions">
                            <div onClick={() => setEditedComment(remark)}>
                              <IconPencil2 size={10} fill="#6D7F92" />
                            </div>
                            <div
                              onClick={() =>
                                setIsDeleteCommentModalOpen(remark)
                              }
                            >
                              <IconTrash size={10} />
                            </div>
                          </div>
                        </div>
                        <div className="comment_content">{remark.message}</div>
                        <div className="comment_footer">
                          <div>
                            <span>
                              {moment(remark.at, "DD/MM/YY.HH:mm")
                                .locale(lng)
                                .fromNow()}
                            </span>
                            <div className="dot" />
                            <div>
                              {remark.plan
                                ? remark.plan.name
                                : remark.card.name}
                            </div>
                          </div>
                          <div>
                            <BarsIcon level={remark.importance} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {tempComment ? (
              <div className="textarea-form">
                <div className="set">
                  <span>Nv. Importance : </span>
                  <div
                    ref={refImportance}
                    className={
                      "rectangular_select" +
                      " " +
                      (importanceDivIsVisible
                        ? "rectangular_select_opened"
                        : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() =>
                        setImportanceDivIsVisible(!importanceDivIsVisible)
                      }
                    >
                      <span>
                        {IMPORTANCE_LEVELS[tempComment.importance - 1].label}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {importanceDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <div className={"rectangular_select_body_options"}>
                          {IMPORTANCE_LEVELS.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setTempComment({
                                    ...tempComment,
                                    importance: item.level,
                                  });
                                  setImportanceDivIsVisible(false);
                                }}
                              >
                                {_(item.label)}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="set">
                  <span>Afficher sur : </span>
                  <div
                    ref={refPlans}
                    className={
                      "rectangular_select" +
                      " " +
                      (planDivIsVisible ? "rectangular_select_opened" : "")
                    }
                  >
                    <div
                      className={"rectangular_select_header"}
                      onClick={() => setPlanDivIsVisible(!planDivIsVisible)}
                    >
                      <span>{tempComment.plan && tempComment.plan.name}</span>
                      <TiArrowSortedDown />
                    </div>
                    {planDivIsVisible && (
                      <div className={"rectangular_select_body"}>
                        <div className={"rectangular_select_body_options"}>
                          {devCard.plans.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={"rectangular_select_body_option"}
                                onClick={() => {
                                  setTempComment({
                                    ...tempComment,
                                    card: null,
                                    plan: {
                                      id: item.id,
                                      name: `PLAN ${item.index + 1}`,
                                    },
                                  });
                                  setPlanDivIsVisible(false);
                                }}
                              >
                                {`PLAN ${devCard.plans.length - index}`}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <textarea
                  onChange={(e) => {
                    setTempComment({
                      ...tempComment,
                      message: e.target.value,
                    });
                  }}
                />
                <div className="actions">
                  <div className="default" onClick={() => setTempComment(null)}>
                    <IconClose size="10" fill="#6D7F92" />
                  </div>
                  <div
                    className="premium"
                    onClick={() => handleSaveComment(tempComment)}
                  >
                    {_("post")}
                  </div>
                </div>
              </div>
            ) : editedComment ? (
              ""
            ) : (
              <div
                className="textarea"
                onClick={() =>
                  setTempComment({
                    message: "",
                    card: { id: devCard.id, name: _("allPlans") },
                    importance: 2,
                  })
                }
              >
                {_("Ajouter une remarque...")}{" "}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  const renderLightFrame = () => {
    return (
      <div className="light_frame">
        <div>
          <div className="frame">
            <div className="title">{_("Plan de correction")}</div>
            <div className="set">
              <label>{_("title")} *</label>
              <input
                value={tempPlan.title}
                onChange={(event) =>
                  setTempPlan({
                    ...tempPlan,
                    title: event.target.value,
                  })
                }
              />
            </div>
            <div className="set">
              <label>{_("description")}</label>
              <textarea
                value={tempPlan.description}
                onChange={(event) =>
                  setTempPlan({
                    ...tempPlan,
                    description: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="panel">
            <div className="set">
              <label>{_("responsable")} *</label>
              <div
                ref={refResponsables}
                className={
                  "rectangular_select" +
                  " " +
                  (responsablesDivIsVisible ? "rectangular_select_opened" : "")
                }
              >
                <div
                  className={"rectangular_select_header"}
                  onClick={() =>
                    setResponsablesDivIsVisible(!responsablesDivIsVisible)
                  }
                >
                  <span>
                    {tempPlan.responsables.length > 0
                      ? tempPlan.responsables[0].name
                      : ""}
                  </span>
                  <TiArrowSortedDown />
                </div>
                {responsablesDivIsVisible && (
                  <div className={"rectangular_select_body"}>
                    <input
                      onChange={(e) => setCollaboratorsKeyWord(e.target.value)}
                    />
                    <div className={"rectangular_select_body_options"}>
                      {collaborators.map((item, index) => {
                        if (!item) {
                          return;
                        }
                        return (
                          <div
                            key={index}
                            className={"rectangular_select_body_option"}
                            onClick={() => {
                              setTempPlan({
                                ...tempPlan,
                                responsables: [item],
                              });
                              setResponsablesDivIsVisible(false);
                            }}
                          >
                            <div className="avatar" key={index}>
                              {renderAvatar(
                                item && avatarCollaborators[item.uid]
                                  ? avatarCollaborators[item.uid].avatarUrl
                                  : null,
                                item.name
                              )}
                            </div>
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="set">
              <label>{_("deadLine")}</label>
              <div className={"calendar_select"}>
                <Calendar
                  showIcon
                  dateFormat="dd/mm/yy"
                  locale={lng}
                  value={tempPlan.deadLine ? tempPlan.deadLine : moment()}
                  className="p-datepicker-sidebar"
                  panelClassName="large_calendar"
                  onChange={(e) =>
                    setTempPlan({
                      ...tempPlan,
                      deadLine: e.value,
                    })
                  }
                  icon={<CalendarIcon fill="#6D7F92" />}
                />
              </div>
            </div>
            <div className="set">
              <label>{_("priorityLevel")}</label>
              <div
                // ref={refPriority}
                className={
                  "rectangular_select " +
                  (priorityDivIsVisible ? "rectangular_select_opened" : "")
                }
                style={{ marginBottom: 23 }}
              >
                <div
                  className={`rectangular_select_header ${"hight_priority"}`}
                  style={{ border: "none" }}
                  onClick={() => setPriorityDivIsVisible(!priorityDivIsVisible)}
                >
                  <span>{tempPlan.priorityLevel.label}</span>
                  <TiArrowSortedDown />
                </div>
                {priorityDivIsVisible && (
                  <div
                    className={"rectangular_select_body"}
                    style={{
                      border: "none",
                      width: 290,
                      boxShadow: "none",
                    }}
                  >
                    <div className={"rectangular_select_body_options"}>
                      {PRIORITY_LEVELS.map((item, index) => (
                        <div
                          key={index}
                          className={`rectangular_select_body_option ${item.value}_priority`}
                          onClick={() => {
                            setTempPlan({
                              ...tempPlan,
                              priorityLevel: item,
                            });
                            setPriorityDivIsVisible(false);
                          }}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer" style={{ width: firstPlan ? "70%" : "53%" }}>
          <div
            className="action_left"
            onClick={() => {
              setAddPlan(false);
              setActivePlan({
                ...devCard.plans[0],
                deadLine: moment(devCard.plans[0].deadLine, "Y-MM-DD HH:mm:ss")
                  ._d,
              });
            }}
          >
            {_("cancel")}
          </div>
          <div
            className={
              "action_right" +
              " " +
              (tempPlan.title && tempPlan.responsables.length > 0
                ? ""
                : "disabled")
            }
            onClick={() => {
              if (
                tempPlan.title === "" ||
                !tempPlan.title ||
                tempPlan.responsables.length === 0
              ) {
                return;
              } else {
                handleSavePlan({
                  title: tempPlan.title,
                  priorityLevel: tempPlan.priorityLevel.label,
                  progression: tempPlan.progression,
                  description: tempPlan.description,
                  deadLine:
                    tempPlan.deadLine instanceof Date &&
                    !isNaN(tempPlan.deadLine)
                      ? moment(tempPlan.deadLine).format("Y-MM-DD HH:mm:ss")
                      : null,
                  responsables: tempPlan.responsables,
                });
              }
            }}
          >
            {_("save")}
          </div>
        </div>
      </div>
    );
  };

  const renderHeavyFrame = () => {
    return (
      <div className="heavy_frame" style={{ height: 464 }}>
        {activePlan && !addPlan && (
          <div>
            <div className="frame">
              <div className="frame_title">
                {editedPlan ? (
                  <input
                    value={editedPlan.title}
                    onChange={(event) => {
                      setEditedPlan({
                        ...editedPlan,
                        title: event.target.value,
                      });
                    }}
                  />
                ) : (
                  <div className="title">{activePlan.title}</div>
                )}
                {!editedPlan && (
                  <div
                    className="smallAction"
                    onClick={() => setEditedPlan(activePlan)}
                  >
                    {_("edit")}
                  </div>
                )}
              </div>
              {editedPlan ? (
                <textarea
                  value={editedPlan.description}
                  onChange={(event) => {
                    setEditedPlan({
                      ...editedPlan,
                      description: event.target.value,
                    });
                  }}
                />
              ) : (
                <div className="b-frame">
                  {activePlan.description.length > 0 && (
                    <p>{activePlan.description}</p>
                  )}
                </div>
              )}
              <div className="frame_title">
                <FrequanceIcon />
                <div className="title title_sub">{_("activities")}</div>
              </div>
              <div className="time_line">
                {activePlan.activities &&
                  activePlan.activities.map((item, index) => (
                    <div className="event">
                      <div className="at">
                        <span>{item.at.split(".")[0]}</span>
                        <span>{item.at.split(".")[1]}</span>
                      </div>
                      <div className="line">
                        <div className="dot" />
                      </div>
                      <div className="details">
                        <span>{item.user}</span>
                        {renderActivityMessage(item.action, item.data)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="panel">
              <div className="title">
                <UserIcon />
                <span>{_("planResponsable")}</span>
              </div>
              <div
                ref={refResponsables}
                className={
                  "rectangular_select" +
                  " " +
                  (responsablesDivIsVisible ? "rectangular_select_opened" : "")
                }
              >
                <div
                  className={"rectangular_select_header"}
                  onClick={() =>
                    setResponsablesDivIsVisible(!responsablesDivIsVisible)
                  }
                >
                  <span>{_("choose")}</span>
                  <TiArrowSortedDown />
                </div>
                {responsablesDivIsVisible && (
                  <div className={"rectangular_select_body"}>
                    <input
                      onChange={(e) => setCollaboratorsKeyWord(e.target.value)}
                    />
                    <div className={"rectangular_select_body_options"}>
                      {collaborators.map((item, index) => {
                        if (!item) {
                          return;
                        }
                        return (
                          <div
                            key={index}
                            className={"rectangular_select_body_option"}
                            onClick={() => {
                              let newResponsables = editedPlan
                                ? [...editedPlan.responsables]
                                : [...activePlan.responsables];
                              newResponsables.push(item);
                              setEditedPlan(
                                editedPlan
                                  ? {
                                      ...editedPlan,
                                      responsables: newResponsables,
                                    }
                                  : {
                                      ...activePlan,
                                      responsables: newResponsables,
                                    }
                              );
                              setResponsablesDivIsVisible(false);
                            }}
                          >
                            <div className="avatar" key={index}>
                              {renderAvatar(
                                item && avatarCollaborators[item.uid]
                                  ? avatarCollaborators[item.uid].avatarUrl
                                  : null,
                                item.name
                              )}
                            </div>
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="list_users">
                {(editedPlan
                  ? editedPlan.responsables
                  : activePlan.responsables
                ).map((item, index) => (
                  <div key={index} className={"user"}>
                    <div className="avatar" key={index}>
                      {renderAvatar(
                        item && avatarCollaborators[item.uid]
                          ? avatarCollaborators[item.uid].avatarUrl
                          : null,
                        item.name
                      )}
                    </div>
                    <div className="infos">
                      <span>{item.name}</span>
                    </div>
                    {devCard.collaborator !== item.id && (
                      <div
                        className="action"
                        onClick={() => {
                          let newResponsables = editedPlan
                            ? [...editedPlan.responsables]
                            : [...activePlan.responsables];
                          newResponsables = newResponsables.filter(
                            (resp) => item.uid !== resp.uid
                          );
                          setEditedPlan(
                            editedPlan
                              ? {
                                  ...editedPlan,
                                  responsables: newResponsables,
                                }
                              : {
                                  ...activePlan,
                                  responsables: newResponsables,
                                }
                          );
                        }}
                      >
                        <IconTrash size={11} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="title">
                <ClockIcon />
                <span>{_("deadLine")}</span>
              </div>
              <div className={"rectangular_select"} style={{ width: 179 }}>
                <Calendar
                  showIcon
                  dateFormat="dd/mm/yy"
                  locale={lng}
                  className="p-datepicker-sidebar"
                  value={editedPlan ? editedPlan.deadLine : activePlan.deadLine}
                  onChange={(e) => {
                    editedPlan
                      ? setEditedPlan({
                          ...editedPlan,
                          deadLine: e.value,
                        })
                      : setEditedPlan({
                          ...activePlan,
                          deadLine: e.value,
                        });
                  }}
                  icon={<CalendarIcon fill="#6D7F92" />}
                ></Calendar>
              </div>
              <div className="list_tags">
                {[15, 30, 45].map((item, index) => (
                  <div
                    key={index}
                    className={"tag"}
                    onClick={() => {
                      let date = moment(
                        editedPlan ? editedPlan.deadLine : activePlan.deadLine
                      );
                      let newDate = date.add(item, "days")._d;
                      editedPlan
                        ? setEditedPlan({
                            ...editedPlan,
                            deadLine: newDate,
                          })
                        : setEditedPlan({
                            ...activePlan,
                            deadLine: newDate,
                          });
                    }}
                  >
                    +{item}j.
                  </div>
                ))}
              </div>
              <div className="title">
                <BookMarkIcon fill="#29394D" />
                <span>{_("progression")}</span>
              </div>
              <div
                ref={refProgression}
                className={
                  "rectangular_select " +
                  (stepsDivIsVisible ? "rectangular_select_opened" : "")
                }
                style={{ marginBottom: 23 }}
              >
                <div
                  className={`rectangular_select_header ${
                    editedPlan ? editedPlan.progression : activePlan.progression
                  }_step`}
                  style={{ border: "none" }}
                  onClick={() => setStepsDivIsVisible(!stepsDivIsVisible)}
                >
                  <span>
                    {_(
                      editedPlan
                        ? editedPlan.progression
                        : activePlan.progression
                    )}
                  </span>
                  <TiArrowSortedDown />
                </div>
                {stepsDivIsVisible && (
                  <div
                    className={"rectangular_select_body"}
                    style={{
                      border: "none",
                      background: `${"moyen"}_state`,
                      width: 178,
                      boxShadow: "none",
                    }}
                  >
                    <div className={"rectangular_select_body_options"}>
                      {[
                        { label: "TODO", step: "todo" },
                        { label: "WIP", step: "wip" },
                        { label: "VALIDATION", step: "validation" },
                        { label: "DONE", step: "done" },
                      ].map((item, index) => {
                        if (item.step === "done" && loggedAs !== "MANAGER") {
                          return;
                        } else {
                          return (
                            <div
                              key={index}
                              className={`rectangular_select_body_option ${item.label}_step`}
                              onClick={() => {
                                editedPlan
                                  ? setEditedPlan({
                                      ...editedPlan,
                                      progression: item.label,
                                    })
                                  : setEditedPlan({
                                      ...activePlan,
                                      progression: item.label,
                                    });
                                setStepsDivIsVisible(false);
                              }}
                            >
                              {_(item.label)}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="title">
                <BellIcon />
                <span>{_("priorityLevel")}</span>
              </div>
              <div
                ref={refPriority}
                className={
                  "rectangular_select " +
                  (priorityDivIsVisible ? "rectangular_select_opened" : "")
                }
                style={{ marginBottom: 23 }}
              >
                <div
                  className={`rectangular_select_header ${"hight_priority"}`}
                  style={{ border: "none" }}
                  onClick={() => setPriorityDivIsVisible(!priorityDivIsVisible)}
                >
                  <span>
                    {_(
                      editedPlan
                        ? editedPlan.priorityLevel
                        : activePlan.priorityLevel
                    )}
                  </span>
                  <TiArrowSortedDown />
                </div>
                {priorityDivIsVisible && (
                  <div
                    className={"rectangular_select_body"}
                    style={{
                      border: "none",
                      width: 178,
                      boxShadow: "none",
                    }}
                  >
                    <div className={"rectangular_select_body_options"}>
                      {[
                        { label: "URGENT", step: "hight" },
                        { label: "MEDIUM", step: "medium" },
                        { label: "LOW", step: "low" },
                      ].map((item, index) => (
                        <div
                          key={index}
                          className={`rectangular_select_body_option ${item.step}_priority`}
                          onClick={() => {
                            editedPlan
                              ? setEditedPlan({
                                  ...editedPlan,
                                  priorityLevel: item.label,
                                })
                              : setEditedPlan({
                                  ...activePlan,
                                  priorityLevel: item.label,
                                });
                            setPriorityDivIsVisible(false);
                          }}
                        >
                          {_(item.label)}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderActivityMessage = (action, data) => {
    switch (action) {
      case "updated":
        return (
          <div>
            {`${_("hasModified")} ${_("the")} ${data.attribute} : `}
            <span>{`${data.from} -> ${data.to}`}</span>
          </div>
        );
      case "addResponsable":
        return (
          <div>
            {`${_("hasAdd")} ${_("asPlanResponsable")} : `}
            <span>{data.name}</span>
          </div>
        );
      case "removeResponsable":
        return (
          <div>
            {`${_("hasRemoved")} ${_("fromPlanResponsables")} : `}
            <span>{data.name}</span>
          </div>
        );
      case "create":
        return (
          <div>
            {`${_("hasCreate")} ${_("thePlan")} : `}
            <span>{data.title}</span>
          </div>
        );
      default:
        return;
    }
  };

  let loading =
    collaboratorsQuery.isFetching || developementCardsQuery.isFetching;

  return (
    <div style={{ width: firstPlan ? 971 : 1244 }}>
      <div className="sidebar_header">
        <div className="sidebar_header_infos">
          <h3>{_("developementCard")}</h3>
        </div>
        <div className="sidebar_header_action" onClick={onClose}>
          <IconClose size={18} />
        </div>
      </div>
      <div className="sidebar_body">
        <div
          className="aside aside-center"
          style={{ width: firstPlan ? "31%" : "21.5%" }}
        >
          <label>{_("Collaborateur concerné ")} : </label>
          <div className="avatar" key="avatar">
            {renderAvatar(collaborator.avatarUrl, collaborator.name)}
          </div>
          <div className="infos">{collaborator.name}</div>
        </div>
        <div className="content">
          {loading ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : devCard ? (
            renderDevelopementCardsFrame()
          ) : (
            renderLightFrame()
          )}
        </div>
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isDeleteCommentModalOpen}
        onCancel={() => setIsDeleteCommentModalOpen(null)}
        onConfirm={() => {
          handleDeleteDevelopementComment(isDeleteCommentModalOpen);
          setIsDeleteCommentModalOpen(null);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("deleteConfirm")}
        text={_("confirmCommentDelete")}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
      <ModalConfirm
        type={"delete"}
        isOpen={isDeletePlanModalOpen}
        onCancel={() => setIsDeletePlanModalOpen(false)}
        onConfirm={() => {
          deleteDevelopementCardPlan();
          setIsDeletePlanModalOpen(false);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("deleteConfirm")}
        text={_("confirmPlanDelete")}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
    </div>
  );
};

export default CollaboratorDevelopmentCard;
